import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useWindowDimensions } from '../../hooks';

export default function FlyoutPane({ children, floatMode, showCloseOverlay, onCloseClick }) {
    const { width: viewportWidth } = useWindowDimensions();
    return (
        <>
            {showCloseOverlay ? (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 8
                    }}
                    onClick={onCloseClick}
                >

                </div>
            ) : null}
            <Grid
                className='flyoutPane'
                item
                style={{ width: `${viewportWidth * .30}px` }}
                sx={{
                    position: floatMode ? 'absolute' : null,
                    top: floatMode ? '88px' : null,
                    left: floatMode ? '1em' : null,
                    zIndex: floatMode ? 9 : null
                }}
            >
                <Paper
                    sx={{
                        p: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: floatMode ? 'calc(100vh - 8.75em)' : 'calc(100vh - 7.5em)',
                        overflow: 'auto',
                    }}
                >
                    {children}
                </Paper>
            </Grid>
        </>
    );
}