import React from 'react';
import { Grid, Paper, Table } from '@mui/material';
import { useFamilyNameList } from '../../fetch/fetchClassificationItems';
import { useModelEmi } from '../../fetch/engineModelFetch';

export default function SerialNumberMatchNode({ serialMatch }) {
    const { familyNames } = useFamilyNameList();
    const { data: emiData, isLoading } = useModelEmi(serialMatch.modelNum);
    const family = familyNames.filter(it => it.id == serialMatch.familyNameId)[0];

    return (
        <Grid item xs={12} md={6}>
            <Paper
                sx={{
                    p: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden',
                    cursor: 'pointer'
                }}
            >
                {!!emiData && !isLoading ? (
                    <Table>
                        <tbody>
                            <tr>
                                <th colSpan={2} style={{ textAlign: 'left' }}>{`${emiData.brand} ${emiData.productName}`}</th>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left' }}>
                                    Serial Number
                                </th>
                                <td style={{ textAlign: 'left' }}>
                                    {serialMatch.engineSerial}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left' }}>
                                    Model Number
                                </th>
                                <td style={{ textAlign: 'left' }}>
                                    {serialMatch.modelNum}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left' }}>
                                    Family
                                </th>
                                <td style={{ textAlign: 'left' }}>
                                    {family.name}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                ) : null}
            </Paper>
        </Grid>
    );
}