import { Divider, List, ListItem, ListItemText, IconButton } from '@mui/material';
import React, { useState } from 'react';
import SearchListItem from '../../SearchListItem';
import { useDocumentMetadataSearch } from '../../../fetch/fetchMetadata';
import SearchEntryButton from './SearchEntryButton';
import ClearFilterListItem from '../../ClearFilterListItem';
import Spinner from '../../spinner/Spinner';
import { isPageNumberShowing } from '../documentViewHelpers';
import CloseIcon from '@mui/icons-material/Close';
import FlyoutPane from '../FlyoutPane';
import { useIsSinglePage } from '../../../hooks';
import { logFeatureUsage } from '../../../fetch/insightsHelper';

export default function DocumentSearch({ onPageClick, currentPage, partNumber, onCloseClick, floatMode, showCloseOverlay }) {
    const [workingQuery, setWorkingQuery] = useState('');
    const [executedQuery, setExecutedQuery] = useState('');
    const { isSinglePageMode } = useIsSinglePage();

    const { items, isLoading } = useDocumentMetadataSearch(partNumber, executedQuery);

    const handleExecuteQuery = () => {
        setExecutedQuery(workingQuery.trim());
        setWorkingQuery(workingQuery.trim());
        logFeatureUsage('InDocumentSearch');
    }

    const handleClearFilters = () => {
        setExecutedQuery('');
        setWorkingQuery('');
    }

    return (
        <FlyoutPane floatMode={floatMode} showCloseOverlay={showCloseOverlay} onCloseClick={onCloseClick}>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItem
                    secondaryAction={
                        <IconButton onClick={onCloseClick} color='primary'>
                            <CloseIcon />
                        </IconButton>
                    }>
                    Document Search
                </ListItem>
                <SearchListItem
                    text='Search Document'
                    workingQuery={workingQuery}
                    onWorkingQueryChanged={setWorkingQuery}
                    onExecutedQuery={handleExecuteQuery}
                />
                <ClearFilterListItem
                    show={executedQuery != ''}
                    text='Clear Search'
                    onClick={handleClearFilters}
                />
                <Divider />
                <div style={{ height: 'calc(100vh - 16em)', overflowY: 'auto' }}>
                    {items.map((item, index) => {
                        const { pageNumber, match } = item;

                        let pageRange = { minimumPageNumber: pageNumber, maximumPageNumber: pageNumber };

                        return (
                            <SearchEntryButton
                                key={index}
                                onClick={() => {
                                    onPageClick(pageNumber);
                                    logFeatureUsage('InDocumentSearch_ClickedResult');
                                }}
                                isPageShowing={isPageNumberShowing(currentPage, pageRange, false, isSinglePageMode)}
                                title={match}
                                query={executedQuery}
                                pageNumber={pageNumber}
                            />
                        )
                    })}
                    {items.length == 0 && !isLoading ? (
                        <ListItem>
                            <ListItemText style={{ textAlign: 'center' }}>No Results</ListItemText>
                        </ListItem>
                    ) : null}
                    {isLoading ? (
                        <ListItem style={{ justifyContent: 'center' }}>
                            <Spinner />
                        </ListItem>
                    ) : null}
                </div>
            </List>
        </FlyoutPane>
    );
}